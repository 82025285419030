<template>
  <div class="animated fadeIn">
    <div v-if="loadingSyncPanel" class="mb-5 pb-5">
      <loading-animation />
    </div>

    <div v-else class="mb-3 mx-3">
      <h4>Última sincronização: {{ syncPanel ? syncPanel.formattedSyncDate : ''}}</h4>
    </div>

    <b-tabs pills card lazy class="col-12">
      <b-tab active>
        <template slot="title">
          Consultar pedido
        </template>

        <div class="row align-items-end mx-md-n3 mx-0 mb-3">
          <div class="col-xl-4 col-md-6 col-sm-auto col-12 mb-sm-0 mb-3">
            <label>Tipo de Pedido:</label>
            <model-list-select
              :list="tableOptions"
              option-value="value"
              option-text="label"
              v-model="selectedTable"
              placeholder="Selecione o Tipo de Pedido"
            />
          </div>

          <div class="col-xl-2 col">
            <b-form-group
              id="transaction"
              label="Transação"
              label-for="transaction-input"
              class="mb-0"
            >
              <b-form-input
                id="transaction-input"
                type="number"
                v-model="transaction"
                @keyup.enter="searchOrderByTransaction"/>
            </b-form-group>
          </div>

          <div class="col-auto">
            <div v-if="loadingOrdersByTransaction" class="mx-5">
              <loading-animation />
            </div>
            <button v-else type="button" class="btn btn-primary" @click="searchOrderByTransaction"
              :disabled="!transaction || !transaction.length || !selectedTable || !selectedTable.value">
              Consultar
            </button>
          </div>
        </div>

        <div v-if="loadingOrdersByTransaction" class="mb-5 pb-5">
          <loading-animation />
        </div>

        <div v-if="!loadingOrdersByTransaction && firstSearch[0] && (!ordersFoundByTransaction || !ordersFoundByTransaction.length)" class="mb-3">
          <div class="row bg-light mx-0 py-3">
            <div class="col text-center">
              Nenhum Pedido encontrado para a pesquisa.
            </div>
          </div>
        </div>

        <div v-if="!loadingOrdersByTransaction && ordersFoundByTransaction && ordersFoundByTransaction.length" class="mb-3">
          <div v-for="(item, index) in ordersFoundByTransaction" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">
            <div class="col-sm-6 col-12">
              <div class="row">
                <div class="col-12">
                  <span class="font-weight-bold">Documento: </span>{{item.number ? item.number : 'Sem documento'}}
                </div>
                <div v-if="item.documentNumber && item.documentNumber.toString().length && item.documentNumber != item.number" class="col-12">
                  <span class="font-weight-bold">Código Interno: </span>{{item.documentNumber}}
                </div>
                <div class="col-12">
                  <span class="font-weight-bold">Operação: </span>
                  <span v-if="item.fiscalOperation" class="badge badge-primary">{{item.fiscalOperation.type}}</span>
                </div>
                <div class="col-12">
                  <span class="font-weight-bold">Status Interno: </span>{{item.movementStatusLabel}}
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="row">
                <div class="col-12">
                  <span v-if="item.fiscalOperation" class="font-weight-bold">
                    {{item.fiscalOperation.type === 'ENT' ? 'Origem' : item.fiscalOperation.type === 'SAI' ? 'Destino' : ''}}:
                  </span>
                  {{item.customer && item.customer.person ? item.customer.person.name : ''}}
                </div>
                <div class="col-12">
                  <span class="font-weight-bold">Armazém: </span>{{item.stockLocale ? item.stockLocale.name : ''}}
                </div>
                <div class="col-12">
                  <span class="font-weight-bold">Situação: </span>{{item.status}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <!--<b-tab @click="tabChanged(1)">
        <template slot="title">
          Últimas sincronizações
        </template>

        <p class="text-center">Essa página está temporariamente desativada</p>-->

        <!-- <div class="row mb-3 mx-md-n3 mx-0 align-items-end">
          <div class="col-xl-3 col-sm-8 col-12 mb-xl-0 mb-3">
            <b-form-group
              id="audit-transaction"
              label="Transação"
              label-for="audit-transaction-input"
              class="mb-0"
            >
              <b-form-input
                id="audit-transaction-input"
                type="number"
                placeholder="Digite para pesquisar"
                v-model="auditTransaction"
                @keyup.enter="auditPageChanged(1)"/>
            </b-form-group>
          </div>
          <div class="col-xl-3 col-sm-4 col-12 mb-xl-0 mb-3">
            <model-list-select
              :list="auditTypeOptions"
              option-value="value"
              option-text="label"
              v-model="auditSelectedTypeOptions"
              placeholder="Selecione o Tipo"
            />
          </div>
          <div class="col-xl-4 col-sm-10 col-12 mb-sm-0 mb-3">
            <model-list-select
              :list="auditTableOptions"
              option-value="value"
              option-text="label"
              v-model="auditSelectedTableOptions"
              placeholder="Selecione o Tipo"
            />
          </div>
          <div class="col-xl-2 col-sm-2 col-12">
            <div class="row justify-content-end">
              <div class="col-sm-auto col-12 mr-md-0 mr-sm-2 mr-0">
                <button type="button" class="btn btn-primary mobile-btn-100" :disabled="loadingAudit" @click="auditPageChanged(1)">Buscar</button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loadingAudit" class="my-5 py-5">
          <loading-animation />
        </div>

        <div v-if="!loadingAudit && firstSearch[1] && (!audits || !audits.length)" class="mb-3">
          <div class="row bg-light mx-0 py-3">
            <div class="col text-center">
              Nenhum registro encontrado para a pesquisa.
            </div>
          </div>
        </div>

        <div v-if="!loadingAudit && audits && audits.length" class="mb-3">
          <div v-for="(audit, index) in audits" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">
            <div class="col-xl-6 col-md-4 col-sm-6 col-12">
              <div class="row">
                <div class="col-12">
                  <span class="font-weight-bold">Data: </span>{{audit.formattedCreatedAt}}
                </div>
                <div v-if="audit.table !== 'items'" class="col-12">
                  <span class="font-weight-bold">Transação: </span>{{audit.message ? audit.message.ref : ''}}
                </div>
                <div v-if="audit.table !== 'items'" class="col-12">
                  <span class="font-weight-bold">Número: </span>{{audit.message ? audit.message.number : ''}}
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-md-8 col-sm-6 col-12">
              <div class="row">
                <div class="col-12">
                  <span class="font-weight-bold">Tipo: </span>
                  <span class="badge" :class="auditTableBadgeClassObject(audit.table)">{{audit.showTable}}</span>
                  <span v-if="audit.type" class="ml-2 badge" :class="auditTypeBadgeClassObject(audit.type)">{{audit.type}}</span>
                </div>
                <div class="col-12">
                  <span class="font-weight-bold">Descrição: </span>{{audit.description}}
                </div>
                <div class="col-12">
                  <span class="font-weight-bold">UUID: </span>{{audit.uuid}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav>
          <b-pagination
            v-if="totalAuditRows"
            align="center"
            :limit="10"
            :total-rows="totalAuditRows"
            :per-page="auditsPerPage"
            prev-text="Anterior"
            next-text="Próximo"
            v-model="currentAuditPage"
            @change="auditPageChanged"/>
        </nav> -->
      <!--</b-tab>-->

      <b-tab @click="tabChanged(1)">
        <template slot="title">
          Registros
        </template>

        <div v-if="loadingRows" class="mb-5 pb-5">
          <loading-animation />
        </div>

        <div v-if="!loadingRows && (!bfUser || !bfUser.registers || !bfUser.registers.length)">
          <div class="row bg-light mx-0 py-3">
            <div class="col text-center">
              Nenhum Registro encontrado
            </div>
          </div>
        </div>

        <div v-if="!loadingRows && bfUser && bfUser.registers && bfUser.registers.length" class="mb-3">
          <div v-for="(register, index) in bfUser.registers" :key="index" class="card">
            <div class="card-header pr-1 cursor-pointer">
              <div class="row">
                <div class="col-auto">
                  {{register.register_number}}
                </div>
                <!--<div class="col-auto pl-0">
                  <span v-if="loadingConnections" class="badge badge-secondary">
                    Carregando..
                  </span>
                  <div v-else>
                    <span v-if="register.connection" class="badge badge-success">Online</span>
                    <span v-else class="badge badge-danger">Offline</span>
                  </div>
                </div>
                <div class="col">
                  <i :class="'fa fa-lg ' + (register.showBody ? 'fa-caret-down' : 'fa-caret-up') + ' mx-3 float-right mt-1'"></i>
                </div>-->
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <!--<div v-if="register.connection" class="col-12">
                  <div class="card">
                    <div class="card-header">Fila de sincronização</div>
                    <div class="card-body">
                      <div class="row mb-3 align-items-end mx-md-n3 mx-0">
                        <div class="col-xl-5 col-sm-7 col-12 mb-sm-0 mb-3">
                          <label>Tabela:</label>
                          <model-list-select
                            :list="syncQueueTableOptions"
                            option-value="value"
                            option-text="title"
                            v-model="selectedSyncQueueTableOptions"
                            placeholder="Selecione a Tabela"
                          />
                        </div>
                        <div class="col">
                          <button type="button" class="btn btn-primary mobile-btn-100" @click="searchSyncQueue(index)"
                            :disabled="!isFenixUpdated3_0_44 || loadingSyncQueue">
                            Consultar
                          </button>
                        </div>
                      </div>

                      <div v-if="!isFenixUpdated3_0_44" class="font-weight-bold text-danger mt-3 mx-md-0 mx-3">
                        <i class="fa fa-exclamation-triangle text-danger mr-1"></i>
                        <span>Seu fênix está desatualizado. Por favor atualize-o para utilizar esse recurso.</span><br>
                        <span>Versão atual: {{fenixVersion}}</span><br>
                        <span>Versão necessária: 3.0.44 (release 12-08-2020_v1 do servidor de notas) ou posterior</span>
                      </div>

                      <div v-if="loadingSyncQueue" class="mb-5 pb-5">
                        <loading-animation />
                      </div>

                      <div v-if="!loadingSyncQueue && firstSearch[register.id] && (!teoSyncs || !teoSyncs.length)">
                        <div class="row bg-light mx-0 py-3">
                          <div class="col text-center">
                            Nenhum registro encontrado na fila
                          </div>
                        </div>
                      </div>

                      <div v-if="!loadingSyncQueue && teoSyncs && teoSyncs.length" class="mb-3">
                        <div v-for="(teoSync, index) in teoSyncs" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">
                          <div class="col-xl-4 col-12">
                            <span class="font-weight-bold">Data: </span>{{teoSync.formattedDate}}
                          </div>
                          <div class="col-xl-4 col-sm-6 col-12">
                            <span class="font-weight-bold">Tabela: </span>{{teoSync.tableTitle}}
                          </div>
                          <div class="col-xl-4 col-sm-6 col-12">
                            <span class="font-weight-bold">Ref: </span>{{teoSync.customPk}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->
                <!--Here-->
                <div v-if="isUserManager && register.register_number" class="col-md-6 col-12">
                  <div class="card">
                    <div class="card-header">Solicitar sincronização</div>
                    <div class="card-body">
                      <div class="mx-md-0 mx-3">
                        <small class="text-danger font-weight-bold">
                          Antes de utilizar esse recurso verifique se o pedido já foi sincronizado
                        </small>
                      </div>

                      <div class="row align-items-end mx-md-n3 mx-0 mt-2">
                        <div class="col-md-12 col-sm-auto col-12 mb-md-3 mb-sm-0 mb-3">
                          <label>Tipo de Pedido:</label>
                          <model-list-select
                            :list="tableOptions"
                            option-value="value"
                            option-text="label"
                            v-model="selectedTable"
                            placeholder="Selecione o Tipo de Pedido"
                          />
                        </div>

                        <div class="col">
                          <b-form-group
                            id="transaction"
                            label="Transação"
                            label-for="transaction-input"
                            class="mb-0"
                          >
                            <b-form-input
                              id="transaction-input"
                              type="number"
                              v-model="transaction"
                              @keyup.enter="requestImediateSync(index)"/>
                          </b-form-group>
                        </div>

                        <div class="col-auto">
                          <div v-if="loadingSyncRequest" class="mx-5">
                            <loading-animation />
                          </div>
                          <button v-else type="button" class="btn btn-primary" @click="requestImediateSync(index)" :disabled="!enableSyncButton">
                            Sincronizar
                          </button>
                        </div>
                      </div>

                      <!--<div v-if="!isFenixUpdated3_0_43" class="font-weight-bold text-danger mt-3 mx-md-0 mx-3">
                        <i class="fa fa-exclamation-triangle text-danger mr-1"></i>
                        <span>Seu fênix está desatualizado. Por favor atualize-o para utilizar esse recurso.</span><br>
                        <span>Versão atual: {{fenixVersion}}</span><br>
                        <span>Versão necessária: 3.0.43 (release 05-08-2020_v1 do servidor de notas) ou posterior</span>
                      </div>-->
                      <div v-if="syncReturnMessage" :class="'mt-3 alert alert-' + syncReturnMessage.color">{{syncReturnMessage.text}}</div>
                    </div>
                  </div>
                </div>

                <div v-if="register.infos" class="col-md-6 col-12">
                  <div class="card">
                    <div class="card-header">Informações do registro</div>
                    <div class="card-body">
                      <div class="px-md-0 px-3">
                        <span class="font-weight-bold">Path: </span>{{register.infos.path}}<br>
                        <span class="font-weight-bold">Código do cliente: </span>{{register.infos.clifor_codigo}}<br>
                        <span class="font-weight-bold">UUID da máquina: </span>{{register.infos.machine_uuid}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
  import { httpClient } from '@/service'
  import { LoadingAnimation } from '@/components/loaders'
  import shared from '@/shared/shared'
  import { ModelListSelect } from 'vue-search-select'
import { clearTimeout, setTimeout } from 'timers';

  export default {
    name: 'sync',
    components: { LoadingAnimation, ModelListSelect },

    data () {
      return {
        loadingRows: false,
        connections: [],
        loadingConnections: false,
        syncPanel: null,
        loadingSyncPanel: false,
        selectedTable: {},
        tableOptions: [
          {
            label: 'Movimentos de Itens (Alocação)',
            value: 'MOVIMENTO_ITENS_MS',
            auditTable: 'item_movements',
            type: 'M'
          },
          {
            label: 'Vendas (Separação)',
            value: 'VENDAS_MS',
            auditTable: 'orders',
            type: 'P'
          },
          {
            label: 'Transferências',
            value: 'MOVIMENTO_ITENS_TRANSF_MS',
            auditTable: 'transfers',
            orderRelationshipFieldName: 'transfer'
          },
          {
            label: 'Transformações',
            value: 'TRANSFORMACAO_ITENS_MS',
            auditTable: 'transformation_ms',
            orderRelationshipFieldName: 'transformationMS'
          },
          {
            label: 'Ordens de produção',
            value: 'PRD_OP_MS',
            auditTable: 'production_ms',
            orderRelationshipFieldName: 'productionMS'
          },
        ],
        transaction: null,
        loadingSyncRequest: false,
        isUserManager: false,
        authUser: false,
        installObjs: [],
        loadingInstallObjs: false,
        wasSyncRequestSentLessThan5SecondsAgo: false,
        socket: null,
        syncReturnMessage: null,
        fenixVersion: null,
        isFenixUpdated3_0_43: false,
        isFenixUpdated3_0_44: false,
        loadingAudit: false,
        audits: [],
        totalAuditRows: 0,
        auditsPerPage: 10,
        currentAuditPage: 1,
        auditTableOptions: [
          { label: 'Todos', value: null },
          { label: 'Itens', value: 'ITENS', auditTable: 'items' }
        ],
        auditTypeOptions: [
          { label: 'Todos', value: null },
          { label: 'INFO', value: 'INFO' },
          { label: 'ERROR', value: 'ERROR' },
        ],
        auditSelectedTableOptions: {},
        auditSelectedTypeOptions: {},
        tabsStarted: {},
        loadingOrdersByTransaction: false,
        ordersFoundByTransaction: null,
        auditTransaction: null,
        firstSearch: {},
        loadingSyncQueue: false,
        teoSyncs: [],
        syncQueueTimeout: null,
        selectedSyncQueueTableOptions: {},
        syncQueueTableOptions: [],
        bfUser: null
      }
    },

    mounted() {
      this.$store.commit('app/SET_CURRENT_PAGE', { title: 'Sincronização', size: 4 });
      this.auditTableOptions.push(...this.tableOptions);
      this.setIsUserManager();
      this.findSyncPanel();
    },

    computed: {
      enableSyncButton() {
        return this.transaction
          && this.transaction.toString().length
          && this.selectedTable
          && this.selectedTable.value
          /*&& !this.loadingInstallObjs
          && this.installObjs
          && this.installObjs.length*/
          && !this.wasSyncRequestSentLessThan5SecondsAgo
          //&& this.isFenixUpdated3_0_43;
      }
    },

    methods: {
      findBfUser() {
        let companyGroup = shared.getCompanyGroup();
        if (companyGroup && companyGroup.alias) {
          this.loadingRows = true;
          let url = `${process.env.VUE_APP_BULLETPROOF_API}/api/do-manager/users/${companyGroup.alias}`;
          httpClient.get(url)
          .then(({ data }) => {
            this.loadingRows = false;
            this.bfUser = data;

            if (this.bfUser && this.bfUser.registers) {
              this.bfUser.registers = this.bfUser.registers.filter(r => r.sync_active === 1);
            }

            //console.log(this.bfUser);
            //this.handleFenixRegisters();
          })
          .catch((error) => {
            this.loadingRows = false;
            if (error.message) {
              this.$notify.error(error)
            }
            this.bfUser = null;
          });
        }
      },

      handleFenixRegisters() {
        if (this.fenixRegisters && this.fenixRegisters.length) {
          if (this.fenixRegisters.length === 1) {
            this.fenixRegisters[0].showBody = true;
          }

          this.fenixRegisters.forEach(r => {
            if (r.infos && r.infos.length) {
              try {
                r.infosObj = JSON.parse(r.infos);
              } catch (e) {
                console.log(e);
              }
            }
          });

          //Para fins de teste
          //this.fenixRegisters[0].connection = {};

          //Para produção
          this.findAllConnections();
        }
      },

      findAllConnections() {
        /*this.loadingConnections = true;
        httpClient.post(`${process.env.VUE_APP_SOCKETIO_URL}poll/channels`, this.fenixRegisters.map(r => r.id))
        .then(({ data }) => {
          this.loadingConnections = false;

          if (data && data.data && data.data.length) {
            this.fenixRegisters.forEach(r => {
              r.connection = data.data.find(c => c.channel === r.id && c.systemName === 'fenix');

              if (r.connection) {
                r.showBody = true;
              }
            });

            this.fenixRegisters.sort((a, b) => {
              if (Boolean(a.connection) === Boolean(b.connection)) {
                return 0;
              } else if (a.connection) {
                return -1;
              } else {
                return 1;
              }
            });
          }
        })
        .catch((error) => {
          this.loadingConnections = false;
          if (error.message) {
            this.$notify.error(error)
          }
          this.connections = [];
        });*/
      },

      /*toggleShowBody(index) {
        this.fenixRegisters[index].showBody = !this.fenixRegisters[index].showBody;

        let fenixRegisters = this.fenixRegisters;
        this.fenixRegisters = [];
        this.fenixRegisters = fenixRegisters;
      },*/

      findSyncPanel(callback) {
        this.loadingSyncPanel = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}sync-panels`)
        .then(({ data }) => {
          this.loadingSyncPanel = false;

          if (data && data.data) {
            if (data.data.syncDate) {
              data.data.formattedSyncDate = shared.formatDate(data.data.syncDate);
            }

            this.syncPanel = data.data;

            if (this.syncPanel.info && this.syncPanel.info.length) {
              try {
                let info = JSON.parse(this.syncPanel.info);
                this.fenixVersion = info.appVersion;

                if (info.appVersion && info.appVersion.length) {
                  let indexOfDash = info.appVersion.indexOf('-');

                  if (indexOfDash > -1) {
                    info.appVersion = info.appVersion.substring(0, indexOfDash);
                  }

                  let versionNumbers = info.appVersion.split('.');

                  //Para produção
                  this.isFenixUpdated3_0_43 = versionNumbers.length >= 3 && (
                    Number(versionNumbers[0]) > 3 || Number(versionNumbers[1]) > 0 || Number(versionNumbers[2]) > 42
                  );

                  this.isFenixUpdated3_0_44 = versionNumbers.length >= 3 && (
                    Number(versionNumbers[0]) > 3 || Number(versionNumbers[1]) > 0 || Number(versionNumbers[2]) > 43
                  );

                  //Para testar
                  /*this.isFenixUpdated3_0_43 = true;
                  this.isFenixUpdated3_0_44 = true;*/
                }
              } catch (e) {
                console.log(e);
              }
            }

            if (callback) {
              callback();
            }
          }
        })
        .catch((error) => {
          this.loadingSyncPanel = false;
          if (error.message) {
            this.$notify.error(error)
          }
          this.syncPanel = null;

          if (callback) {
            callback(error);
          }
        });
      },

      requestImediateSync(index) {
        if (this.loadingSyncRequest) {
          return;
        }

        this.syncReturnMessage = null;

        if (!this.selectedTable || !this.selectedTable.value) {
          this.$notify.textError('Por favor informe o tipo de pedido');
          return;
        }

        if (!this.transaction || !this.transaction.toString().length) {
          this.$notify.textError('Por favor informe a transação do pedido');
          return;
        }

        /*if (!this.isFenixUpdated3_0_43) {
          this.$notify.textError('Seu fênix não possui esse recurso');
          return;
        }*/

        if (!this.isUserManager) {
          this.$notify.textError('Você não possui permissão para utilizar esse recurso');
          return;
        }

        let installObj = {};
        installObj.table = this.selectedTable.value;
        installObj.page = 0;
        installObj.size = 1;
        installObj.filters = {
          TRANSACAO: this.transaction
        };

        //this.listenToRegisterChannels(index);
        this.loadingSyncRequest = true;
        let url = `${process.env.VUE_APP_BULLETPROOF_API}/api/do-manager/install/${this.bfUser.registers[index].register_number}`;
        httpClient.post(url, [installObj])
        .then(({ data }) => {
          this.handleImediateSyncRequestCallback();
          this.loadingSyncRequest = false;

          if (data && data.message) {
            this.$notify.success(
              'Solicitação de sincronização do pedido ' + this.transaction + " - " + this.selectedTable.label + ' enviada com sucesso'
            );
          } else {
            console.log(data);
            this.$notify.textError(
              'Houve um erro ao solicitar a sincronização do pedido ' + this.transaction + " - " + this.selectedTable.label
            );
          }
        })
        .catch((error) => {
          this.handleImediateSyncRequestCallback();
          this.loadingSyncRequest = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      setIsUserManager() {
        this.authUser = shared.getLocalStorageObj('auth/user');

        if (this.authUser && this.authUser.profile && this.authUser.profile.modules) {
          let adjustmentsProfileModule = this.authUser.profile.modules.find(pm => {
            return pm.name && pm.name.toString().toUpperCase() == "ORDERS"
          });

          this.isUserManager = adjustmentsProfileModule && adjustmentsProfileModule.canExecute;
        }
      },

      findAllInstallObjs() {
        if (this.loadingInstallObjs) {
          return;
        }

        this.loadingInstallObjs = true;
        httpClient.get(`${process.env.VUE_APP_API_PHP}api/manager/installers`)
        .then(({ data }) => {
          this.loadingInstallObjs = false;
          this.installObjs = data;
          this.syncQueueTableOptions = [{ value: null, title: 'Todas' }];

          if (this.installObjs) {
            this.syncQueueTableOptions.push(...this.installObjs);
          }
        })
        .catch((error) => {
          this.loadingInstallObjs = false;
          if (error.message) {
            this.$notify.error(error)
          }
          this.installObjs = [];
        });
      },

      handleImediateSyncRequestCallback() {
        this.wasSyncRequestSentLessThan5SecondsAgo = true;

        setTimeout(() => {
          this.wasSyncRequestSentLessThan5SecondsAgo = false;
        }, 5000)
      },

      listenToRegisterChannels(index) {
        if (this.socket && !this.fenixRegisters[index].listeningToChannels) {
          this.socket.on(this.fenixRegisters[index].id + '-progress', data => {
            if (data && data.msg && data.msg.length) {
              try {
                let msg = JSON.parse(data.msg);
                let label = '';

                if (msg.tableName && msg.tableName.length) {
                  label = msg.tableName;
                  let tableOption = this.tableOptions.find(o => o.value === msg.tableName);

                  if (tableOption) {
                    label = tableOption.label;
                  }
                }

                if (msg.count > 0) {
                  this.syncReturnMessage = { color: 'success', text: 'Pedido de ' + label + ' sincronizado com sucesso' };
                } else {
                  this.syncReturnMessage = { color: 'danger', text: 'Pedido de ' + label + ' não encontrado' };
                }
              } catch (e) {
                console.log(e);
              }
            }
          });

          this.socket.on(this.fenixRegisters[index].id + '-status', data => {
            console.log(data);
            this.$notify.warn('Status de sincronização recebido');
          });

          this.socket.on(this.fenixRegisters[index].id + '-error', data => {
            console.log(data);
            this.$notify.warn('Ocorreu um erro na sincronização');
          });

          this.socket.on(this.fenixRegisters[index].id + '.getTeoSyncResponse', data => {
            this.loadingSyncQueue = false;

            try {
              if (data.msg && data.msg.length) {
                let msg = JSON.parse(data.msg);

                if (msg.data) {
                  this.teoSyncs = msg.data;

                  this.teoSyncs.forEach(t => {
                    t.formattedDate = shared.formatDate(t.data);
                    t.tableTitle = t.table;

                    if (t.table && this.installObjs) {
                      let installObj = this.installObjs.find(io => io.value === t.table);

                      if (installObj) {
                        t.tableTitle = installObj.title;
                      }
                    }
                  });
                }
              }
            } catch (e) {
              console.log(e);
            }
          });

          this.fenixRegisters[index].listeningToChannels = true;
        }
      },

      findAllAudit() {
        /*this.firstSearch[1] = true;

        if (this.authUser && this.authUser.companyGroup) {
          let params = {
            companyGroupId: this.authUser.companyGroup.id,
            page: this.currentAuditPage - 1,
            size: this.auditsPerPage
          };

          if (this.auditTransaction && this.auditTransaction.length) {
            params['message.ref'] = this.auditTransaction;
          }

          if (this.auditSelectedTableOptions && this.auditSelectedTableOptions.auditTable) {
            params.table = this.auditSelectedTableOptions.auditTable;
          }

          if (this.auditSelectedTypeOptions && this.auditSelectedTypeOptions.value) {
            params.type = this.auditSelectedTypeOptions.value;
          }

          this.loadingAudit = true;
          httpClient.get(`${process.env.VUE_APP_SOCKETIO_URL}audit`, {
            params: params
          }).then(({ data }) => {
            this.loadingAudit = false;

            if (data.data && data.data.content) {
              this.totalAuditRows = data.data.totalElements;
              this.currentAuditPage = data.data.number + 1;
              this.audits = data.data.content;

              this.audits.forEach(a => {
                if (a.created_at) {
                  a.formattedCreatedAt = shared.formatDate(a.created_at);
                }

                let obj = this.auditTableOptions.find(o => o.auditTable && o.auditTable === a.table);

                if (obj) {
                  a.showTable = obj.label;
                } else {
                  a.showTable = a.table;
                }
              });
            }
          })
          .catch((error) => {
            this.loadingAudit = false;
            if (error.message) {
              this.$notify.error(error)
            }
            this.audits = [];
          });
        }*/
      },

      auditPageChanged(page) {
        this.currentAuditPage = page;
        this.findAllAudit();
      },

      tabChanged(index) {
        //if (!this.tabsStarted[index]) {
          switch (index) {
            case 1: {
              /*this.socket = shared.getSocketIOClient();
              this.findAll();
              this.findAllInstallObjs();*/
              if (!this.bfUser) {
                this.findBfUser();
              }

              break;
            }
          }

          //this.tabsStarted[index] = true;
        //}
      },

      auditTypeBadgeClassObject(auditType) {
        return {
          'badge-info': auditType === 'INFO',
          'badge-danger': auditType === 'ERROR',
        }
      },

      searchOrderByTransaction() {
        this.firstSearch[0] = true;

        if (this.loadingOrdersByTransaction) {
          return;
        }

        if (!this.selectedTable || !this.selectedTable.value) {
          this.$notify.textError('Por favor informe o tipo de pedido');
          return;
        }

        if (!this.transaction || !this.transaction.toString().length) {
          this.$notify.textError('Por favor informe a transação do pedido');
          return;
        }

        let refField = 'ref';

        if (this.selectedTable.orderRelationshipFieldName) {
          refField = this.selectedTable.orderRelationshipFieldName + '.' + refField;
        }

        let conditions = [{
          field: refField,
          value: this.transaction
        }];

        if (this.selectedTable.type) {
          conditions.push({
            field: 'type',
            value: this.selectedTable.type
          });
        }

        this.ordersFoundByTransaction = [];
        this.loadingOrdersByTransaction = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}orders/condition`, {
          conditions: conditions
        }).then(({ data }) => {
          this.loadingOrdersByTransaction = false;

          if (data.data && data.data.content) {
            this.ordersFoundByTransaction = data.data.content;

            this.ordersFoundByTransaction.forEach(order => {
              let alocacaoSeparacao = '';

              if (order.fiscalOperation) {
                if (order.fiscalOperation.type === 'ENT') {
                  alocacaoSeparacao= 'Alocação ';
                } else if (order.fiscalOperation.type === 'SAI') {
                  alocacaoSeparacao = 'Separação ';
                }
              }

              if (order.movementStatus && order.movementStatus.length) {
                order.movementStatusLabel = order.movementStatus + ' - ' ;
              } else if (order.movementStatus) {
                order.movementStatusLabel = 'vazio - ' ;
              } else {
                order.movementStatusLabel = 'null - ' ;
              }

              switch (order.movementStatus) {
                case 'P': order.movementStatusLabel += 'Aguardando Liberação'; break;

                case null:
                case undefined:
                case 'L': order.movementStatusLabel += alocacaoSeparacao + 'Pendente'; break;

                case 'N': order.movementStatusLabel += 'Não liberado'; break;
                case 'O': order.movementStatusLabel += alocacaoSeparacao + 'Finalizada'; break;
                case 'B': order.movementStatusLabel += 'Aguardando agendamento'; break;
                case 'C': order.movementStatusLabel += 'Em conferência'; break;
                case 'K': order.movementStatusLabel += 'Conferência finalizada aguardando liberação'; break;
                case 'E': order.movementStatusLabel += 'Conferência finalizada'; break;
                case 'X': order.movementStatusLabel += 'Cancelado'; break;
                case 'D': order.movementStatusLabel += 'Em Desconformidade'; break;
              }
            });
          }
        })
        .catch((error) => {
          this.loadingOrdersByTransaction = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      auditTableBadgeClassObject(table) {
        return {
          'badge-primary': table === 'orders' || table === 'item_movements',
          'badge-warning': table === 'production_ms',
          'badge-success': table === 'transformation_ms',
          'orange-background': table === 'transfers'
        }
      },

      searchSyncQueue(index) {
        /*if (this.loadingSyncQueue) {
          return;
        }

        if (!this.isFenixUpdated3_0_43) {
          this.$notify.textError('Seu fênix não possui esse recurso');
          return;
        }

        if (this.syncQueueTimeout) {
          clearTimeout(this.syncQueueTimeout)
        }

        this.firstSearch[this.fenixRegisters[index].id] = true;
        this.teoSyncs = [];
        this.loadingSyncQueue = true;
        this.findSyncPanel((error) => {
          if (error || !this.syncPanel || !this.syncPanel.syncDate) {
            this.loadingSyncQueue = false;
          } else {
            //Para testar
            //let data = new Date(2020, 6, 1, 0, 0, 0, 0);

            //Para produção
            let data = new Date(this.syncPanel.syncDate);

            let table = null;
            if (this.selectedSyncQueueTableOptions && this.selectedSyncQueueTableOptions.value) {
              table = this.selectedSyncQueueTableOptions.value;
            }

            this.listenToRegisterChannels(index);
            httpClient.post(`${process.env.VUE_APP_SOCKETIO_URL}raw-notification`, {
              channel: this.fenixRegisters[index].id + '.getTeoSync',
              message: {
                data: {
                  data: data.getTime(),
                  table: table
                }
              }
            }).then(({ data }) => {
              this.$notify.success('Solicitação de consulta enviada com sucesso. Por favor aguarde.');

              this.syncQueueTimeout = setTimeout(() => {
                if (this.loadingSyncQueue) {
                  this.loadingSyncQueue = false;
                  this.$notify.warn('Tempo limite para a consulta da fila de sincronização atingido. Por favor aguarde ou tente novamente');
                }
              }, 30000);
            })
            .catch((error) => {
              this.loadingSyncQueue = false;
              if (error.message) {
                this.$notify.error(error)
              }
            });
          }
        });*/
      }
    }
  }
</script>

<style scoped>

</style>
